import React, { Fragment } from "react";
import { Gif } from "@giphy/react-components";
import { getFileUrl } from "utils/files";
import { DownloadOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { ReactTinyLink } from "react-tiny-link";
import { LinkPreviewStyle } from "./ActivityFeedItemContent.styles";
import Linkify from "react-linkify";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import YouTube from "react-youtube";
import { Typography, Row, Col, Image, Button } from "antd";
import PropTypes from "prop-types";
import getYouTubeID from "get-youtube-id";
import ActivityFeedInput from "../ActivityFeedInput/index";
import Mentionify from "../Mentionify/index";

const getUrls = (text) => {
  const rx = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gm;
  return text.match(rx) || [];
};

const getUriType = (url) => {
  const urlInstance = new URL(url);
  if (
    (urlInstance.hostname === "twitch.tv" ||
      urlInstance.hostname === "www.twitch.tv") &&
    !!getTwitchUserOrVideo(url)
  )
    return "twitch";
  if (
    (urlInstance.hostname === "youtube.com" ||
      urlInstance.hostname === "www.youtube.com") &&
    !!getYouTubeID(url)
  )
    return "youtube";
  return "externalLink";
};

const getTwitchUserOrVideo = (url) => {
  const urlInstance = new URL(url);
  if (!urlInstance.pathname.split("/")?.[1]) return null;

  return urlInstance.pathname.split("/")?.[1] === "videos"
    ? { type: "video", value: urlInstance.pathname.split("/")?.[2] }
    : { type: "user", value: urlInstance.pathname.split("/")?.[1] };
};

const ActivityFeedItemContent = ({
  activityFeedItem,
  queries,
  isEditing,
  setIsEditing,
}) => (
  <>
    {!!activityFeedItem.title && (
      <Row>
        <Col span={24}>
          <Typography.Title level={5}>
            {activityFeedItem.title}
          </Typography.Title>
        </Col>
      </Row>
    )}
    <Row>
      <Col span={24} style={{ whiteSpace: "pre-line" }}>
        {!isEditing ? (
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            <Mentionify mentions={activityFeedItem.mentions}>
              {activityFeedItem.description}
            </Mentionify>
          </Linkify>
        ) : (
          <ActivityFeedInput
            queries={queries}
            activityFeedItem={activityFeedItem}
            onUpdate={() => setIsEditing(false)}
          />
        )}
        {(activityFeedItem.files?.filter((file) =>
          file?.mime?.includes("image/")
        )?.length > 0 ||
          activityFeedItem.gifs?.length > 0) && (
          <Row gutter={[10, 10]} className="mt-3">
            {activityFeedItem.files
              ?.filter((file) => file?.mime?.includes("image/"))
              ?.map((image, i) => (
                <Col key={i}>
                  <Image
                    src={getFileUrl(image)}
                    preview={{ src: getFileUrl(image, "original") }}
                  />
                </Col>
              ))}
            {activityFeedItem.gifs?.map(({ gif }, i) => (
              <Col key={i}>
                <Gif gif={gif} height={200} noLink hideAttribution />
              </Col>
            ))}
          </Row>
        )}
        {activityFeedItem.files?.filter(
          (file) => !file?.mime?.includes("image/")
        )?.length > 0 && (
          <Row justify="end">
            {activityFeedItem.files
              ?.filter((file) => !file?.mime?.includes("image/"))
              ?.map((file, i) => (
                <Col key={i}>
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      window.open(getFileUrl(file));
                    }}
                  >
                    {file.name}
                  </Button>
                </Col>
              ))}
          </Row>
        )}
      </Col>
      {getUrls(activityFeedItem.description).length > 0 && (
        <Col span={24} className="text-align-center">
          {getUrls(activityFeedItem.description).map((url, i) => (
            <Fragment key={i}>
              {getUriType(url) === "twitch" && !!getTwitchUserOrVideo(url) ? (
                getTwitchUserOrVideo(url)?.type === "user" ? (
                  <ReactTwitchEmbedVideo
                    layout="video"
                    targetId={uuidv4()}
                    channel={getTwitchUserOrVideo(url)?.value}
                    autoplay={false}
                    width="100%"
                  />
                ) : (
                  <ReactTwitchEmbedVideo
                    targetId={uuidv4()}
                    video={getTwitchUserOrVideo(url)?.value}
                    autoplay={false}
                    width="100%"
                  />
                )
              ) : getUriType(url) === "youtube" ? (
                <YouTube
                  videoId={getYouTubeID(url)}
                  opts={{
                    width: "100%",
                  }}
                />
              ) : (
                <LinkPreviewStyle>
                  <ReactTinyLink
                    cardSize="small"
                    showGraphic={true}
                    maxLine={2}
                    minLine={1}
                    proxyUrl={process.env.REACT_APP_AUTH_HOST + "/proxy"}
                    url={url}
                  />
                </LinkPreviewStyle>
              )}
            </Fragment>
          ))}
        </Col>
      )}
    </Row>
  </>
);

ActivityFeedItemContent.propTypes = {
  activityFeedItem: PropTypes.object,
  queries: PropTypes.object,
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
};

ActivityFeedItemContent.defaultProps = {
  isEditing: false,
};

export default ActivityFeedItemContent;
