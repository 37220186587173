import React, { useContext, useState, useRef } from "react";
import AuthContext from "context/AuthContext";
import ActiveMembers from "../components/clan/main/ActiveMembers";
import GetUserState from "services/core/GetUserState";

const ActiveMembersContainer = () => {
  const { currentMembers: users = [] } = useContext(AuthContext);

  const { currentGame } = useContext(AuthContext);
  const [userWithStates, setUserWithStates] = useState(users);
  const fetchedUsers = useRef([]);

  for (let i = 0; i < users.length; ++i) {
    if (!fetchedUsers.current.includes(users[i].id)) {
      fetchedUsers.current.push(users[i].id);
      GetUserState.perform(users[i], currentGame.code).then(
        ({ success, user }) => {
          if (success) {
            setUserWithStates((userWithStates) => {
              let tempUserWithStates = [...userWithStates];
              tempUserWithStates[i] = user;
              return tempUserWithStates;
            });
          }
        }
      );
    }
  }

  let playingUsers = userWithStates.filter((user) => !!user.isPlaying);
  let connectedUsers = userWithStates.filter(
    (user) => !!user.isConnected && !user.isPlaying
  );
  let noConnectedUsers = userWithStates.filter(
    (user) => !user.isPlaying && !user.isConnected
  );

  playingUsers = playingUsers.sort(
    (a, b) => (b.lastMatchAtUnix || 0) - (a.lastMatchAtUnix || 0)
  );
  connectedUsers = connectedUsers.sort(
    (a, b) => (b.lastMatchAtUnix || 0) - (a.lastMatchAtUnix || 0)
  );
  noConnectedUsers = noConnectedUsers.sort(
    (a, b) => (b.lastMatchAtUnix || 0) - (a.lastMatchAtUnix || 0)
  );
  const sortedUsers = playingUsers
    .concat(connectedUsers)
    .concat(noConnectedUsers);

  return <ActiveMembers users={sortedUsers} />;
};

export default ActiveMembersContainer;
