import React, { useContext, useEffect } from "react";
import { Card, Col, Row, Timeline, Typography } from "antd";
import Moment from "react-moment";
import InfiniteScroll from "react-infinite-scroller";
import Loading from "components/application-state/Loading";
import PropTypes from "prop-types";
//import { Test } from './Notifications.styles';
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import AuthContext from "../../../context/AuthContext";

const Notifications = ({ notifications, loadMore, hasMore }) => {
  const { user, currentClan } = useContext(AuthContext);

  const markSeenNotificationsQuery = loader(
    "queries/notifications/markSeenNotifications.graphql"
  );
  const countUnseenNotificationsQuery = loader(
    "queries/notifications/countUnseenNotifications.graphql"
  );

  const [markSeenNotifications] = useMutation(markSeenNotificationsQuery, {
    refetchQueries: [
      {
        query: countUnseenNotificationsQuery,
        variables: {
          userId: user?.id,
          clanId: currentClan?.id,
        },
      },
    ],
  });

  useEffect(() => {
    markSeenNotifications({
      variables: {
        userId: user?.id,
        clanId: currentClan?.id,
      },
    });
  }, [markSeenNotifications, currentClan?.id, user?.id]);

  return (
    <InfiniteScroll
      className="w-full"
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      useWindow={false}
      loader={<Loading key={-1} fullHeight={false} />}
    >
      <Timeline>
        {notifications.map((notification, i) => (
          <Timeline.Item key={i}>
            <Card>
              <Row gutter={[0, 5]}>
                <Col span={24}>
                  <Moment fromNow>{notification.created_at}</Moment>
                </Col>
                <Col span={24}>
                  <Typography.Title level={5}>
                    {notification.title}
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <Typography.Text>{notification.description}</Typography.Text>
                </Col>
              </Row>
            </Card>
          </Timeline.Item>
        ))}
      </Timeline>
    </InfiniteScroll>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
};

Notifications.defaultProps = {
  notifications: [],
};

export default Notifications;
