// import TournamentsContainer from "containers/TournamentsContainer";
import React from "react";
import ClanLayout from "./ClanLayout";
import { Row, Col } from "antd";
import ActivityFeedContainer from "../../containers/ActivityFeedContainer";
import ActiveMembersContainer from "../../containers/ActiveMembersContainer";
import PersonalStatsContainer from "../../containers/PersonalStatsContainer";
import StoriesContainer from "../../containers/StoriesContainer";
import LeaderboardContainer from "../../containers/LeaderboardContainer";
import ClanStatsContainer from "../../containers/ClansStatsContainer";

const Main = () => {
  return (
    <ClanLayout>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} xxl={{ span: 20, offset: 2 }}>
          <Row gutter={[18, 10]}>
            <Col span={24}>
              <StoriesContainer />
            </Col>
            <Col xs={0} md={6}>
              <Row gutter={[0, 18]}>
                <Col span={24}>
                  <ClanStatsContainer />
                </Col>
                <Col span={24}>
                  <LeaderboardContainer />
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <ActivityFeedContainer />
                </Col>
              </Row>
            </Col>
            <Col xs={0} md={6}>
              <Row gutter={[0, 18]}>
                <Col span={24}>
                  <PersonalStatsContainer />
                </Col>
                {/* <Col span={24}>
                  <TournamentsContainer />
                </Col> */}
                <Col span={24}>
                  <ActiveMembersContainer />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </ClanLayout>
  );
};

export default Main;
