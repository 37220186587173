class PersonalStat {
  constructor({
    type,
    rank,
    rating,
    previous_rating,
    highest_rating,
    streak,
    lowest_streak,
    highest_streak,
    matches,
    wins,
    losses,
    drops,
    last_match,
    last_match_time,
    ratingHistory = [],
  }) {
    this.type = type;
    this.rank = rank;
    this.rating = rating;
    this.previousRating = previous_rating;
    this.highestRating = highest_rating;
    this.streak = streak;
    this.lowestStreak = lowest_streak;
    this.highestStreak = highest_streak;
    this.matches = matches;
    this.wins = wins;
    this.losses = losses;
    this.drops = drops;
    this.lastMatch = last_match;
    this.lastMatchTime = last_match_time;
    this.ratingHistory = ratingHistory;
  }

  toJson() {
    return {
      type: this.type,
      rank: this.rank,
      rating: this.rating,
      previousRating: this.previousRating,
      highestRating: this.highestRating,
      streak: this.streak,
      lowestStreak: this.lowestStreak,
      highestStreak: this.highestStreak,
      matches: this.matches,
      wins: this.wins,
      losses: this.losses,
      drops: this.drops,
      lastMatch: this.lastMatch,
      lastMatchTime: this.lastMatchTime,
      ratingHistory: this.ratingHistory.map((h) => h.toJson()),
    };
  }
}

export default PersonalStat;
