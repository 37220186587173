import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, List, Col, Row } from "antd";
import MemberState from "../MemberState";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
// import { Test } from './ActiveMembers.styles';

const ActiveMembers = ({ t, users }) => {
  const [showAllUsers, setShowAllUsers] = useState(false);

  const connectedUsers = users.filter((user) => user.isConnected).length;
  const limitedUsers = showAllUsers
    ? users
    : users.slice(0, window.Math.max(connectedUsers, 5));

  return (
    <Card
      title={
        <>
          <UserOutlined className="mr-2" />
          {t("Active members")}
        </>
      }
      className="ant-card-with-opacity ant-card-without-padding"
    >
      <Row>
        <Col span={24}>
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={limitedUsers}
            renderItem={(user) => <MemberState user={user} />}
          ></List>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Button
            type="link"
            icon={showAllUsers ? <CaretUpOutlined /> : <CaretDownOutlined />}
            onClick={() => setShowAllUsers(!showAllUsers)}
          >
            {showAllUsers ? t("Hide all members") : t("Show all members")}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

ActiveMembers.propTypes = {
  users: PropTypes.array,
};

ActiveMembers.defaultProps = {
  users: [],
};

export default withTranslation()(ActiveMembers);
