import React from "react";
import Flicking from "@egjs/react-flicking";
import { Fade, AutoPlay } from "@egjs/flicking-plugins";
import { Typography, Row, Col } from "antd";
import LoginButton from "../LoginButton";

const Hero = () => {
  const plugins = [new Fade(), new AutoPlay(2000, "NEXT")];

  return (
    <Row
      className="text-center"
      justify="center"
      align="middle"
      style={{ height: 600 }}
    >
      <Col className="w-full">
        <Row justify="center">
          <Col>
            <Typography.Title className="heading">
              Un clan latinoamericano de Age Of Empires 2 Definitive Edition.
            </Typography.Title>
            <Typography.Title level={3}>
              Aprendemos y nos divertimos en comunidad jugando a nivel
              competitivo RM.
            </Typography.Title>
            <Flicking
              duration={2000}
              className="flicking flicking0"
              gap={10}
              circular={true}
              moveType={{ type: "snap", count: 1 }}
              plugins={plugins}
              width={400}
            >
              <div className="panel">Partidas en equipo</div>
              <div className="panel">Torneos</div>
              <div className="panel">Sesiones de aprendizaje</div>
              <div className="panel">Competencia</div>
              <div className="panel">Comunidad internacional</div>
            </Flicking>
          </Col>
        </Row>
        <Row justify="center" className="mt-10">
          {/*
            <Col>
              <Button type="primary" shape="round" size="large" className="m-5" style={{width: 200}}>
                Unirme al clan
              </Button>
            </Col>
            <Col>
              <Button type="primary" ghost shape="round" size="large" className="m-5" style={{width: 200}}>
                Retar al clan
              </Button>
            </Col>
          */}
          <Col>
            <LoginButton steam={false} size="large" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Hero;
