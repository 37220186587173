export const getFileUrl = (file, format = "small") => {
  if (file?.mime?.includes("image/")) {
    return (
      process.env.REACT_APP_IMAGE_HOST +
      (format === "original"
        ? file?.url
        : file?.formats?.[format]?.url || file?.url)
    );
  } else {
    return process.env.REACT_APP_IMAGE_HOST + file?.url;
  }
};
