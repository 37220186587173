import React from "react";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { getFileUrl } from "utils/files";
import { Avatar, Image } from "antd";
import PropTypes from "prop-types";
//import { Test } from './UserAvatar.styles';

const UserAvatar = ({
  user,
  loading,
  iconFontSize,
  previewImage,
  size,
  ...rest
}) =>
  !!user?.avatar ? (
    <Avatar
      src={
        loading ? (
          <LoadingOutlined />
        ) : (
          <Image
            preview={
              previewImage !== undefined
                ? previewImage
                : {
                    mask: <></>,
                    src: getFileUrl(user.avatar, "web"),
                  }
            }
            src={getFileUrl(user.avatar, "thumbnail")}
          />
        )
      }
      size={size}
      {...rest}
    />
  ) : (
    <Avatar
      icon={
        loading ? (
          <LoadingOutlined />
        ) : (
          <UserOutlined style={{ fontSize: iconFontSize }} />
        )
      }
      size={size}
      {...rest}
    />
  );

UserAvatar.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
  iconFontSize: PropTypes.any,
  size: PropTypes.any,
  previewImage: PropTypes.any,
};

UserAvatar.defaultProps = {
  loading: false,
  iconFontSize: 22,
  size: "large",
};

export default UserAvatar;
