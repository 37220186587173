import { createContext } from "react";

const AuthContext = createContext({
  isAuthenticated: false,
  token: null,
  user: null,
  setUser: null,
  currentClan: null,
  currentGame: null,
  currentMembers: null,
});

export default AuthContext;
