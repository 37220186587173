import React from "react";
import steam_sign_in from "assets/img/steam/sign_in.png";
import { Button } from "antd";
import { withTranslation } from "react-i18next";

const LoginButton = ({ t, steam = true, tReady, ...props }) => {
  const handleSignIn = (e) => {
    e.preventDefault();
    window.location.href = `${process.env.REACT_APP_AUTH_HOST}/auth/steam`;
  };

  return !!steam ? (
    <Button onClick={handleSignIn} style={{ border: 0 }}>
      <img
        src={steam_sign_in}
        alt="Sign in"
        onClick={handleSignIn}
        style={{ cursor: "pointer" }}
      />
    </Button>
  ) : (
    <Button type="primary" {...props} onClick={handleSignIn}>
      {t("Sign in")}
    </Button>
  );
};

export default withTranslation()(LoginButton);
