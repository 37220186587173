import React, { useState } from "react";
import { Layout, Drawer } from "antd";
import background from "assets/img/aoe2de/background-1.jpg";
import Profile from "./Profile";
import { withTranslation } from "react-i18next";
import NavbarContainer from "../../containers/NavbarContainer";
import NotificationsContainer from "../../containers/NotificationsContainer";

const ClanLayout = ({ children, t }) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  return (
    <>
      <Layout
        className="layout"
        style={{
          backgroundImage: `url(${background})`,
          bacgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          minHeight: "100%",
          height: "auto",
        }}
      >
        <Layout.Header
          style={{
            height: "5rem",
            lineHeight: "5rem",
            zIndex: 999,
            position: "fixed",
            width: "100%",
          }}
        >
          <NavbarContainer
            setShowProfileMenu={setShowProfileMenu}
            setShowNotifications={setShowNotifications}
          />
        </Layout.Header>
        <Layout.Content>
          <div
            className="site-layout-content"
            style={{
              padding: "100px 0px",
              minHeight: window.innerHeight - 70,
            }}
          >
            {children}
          </div>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: "center" }}>
          ReBeL KLaN having fun with AOE2 since 2002.
        </Layout.Footer>
      </Layout>
      <Drawer
        title={t("Profile")}
        key={"right-1"}
        placement={"right"}
        closable={true}
        onClose={() => setShowProfileMenu(false)}
        width={400}
        visible={showProfileMenu}
      >
        <Profile />
      </Drawer>
      <Drawer
        title={t("Notifications")}
        key={"right-2"}
        placement={"right"}
        closable={true}
        onClose={() => setShowNotifications(false)}
        width={500}
        visible={showNotifications}
      >
        <NotificationsContainer />
      </Drawer>
    </>
  );
};

export default withTranslation()(ClanLayout);
