import React from "react";
import "./App.scss";
import Main from "./components/Main";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./config/apolloClient";
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import { ConfigProvider } from "antd";
import { getLanguage } from "./config/i18n";

const antdLocales = { en: enUS, es: esES, es_AR: esES, es_MX: esES };

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={antdLocales[getLanguage()]}>
        <Main />
      </ConfigProvider>
    </ApolloProvider>
  );
}

export default App;
