import React from "react";
import PropTypes from "prop-types";
import ActivityFeedItem from "../ActivityFeedItem/ActivityFeedItem";
import { withTranslation } from "react-i18next";
import { Typography, Row, Col } from "antd";
import ActivityFeedInput from "../ActivityFeedInput";
import InfiniteScroll from "react-infinite-scroller";
import Loading from "components/application-state/Loading";
//import { Test } from './ActivityFeed.styles';

const ActivityFeed = ({ t, activityFeedItems, queries, loadMore, hasMore }) => (
  <>
    <Row gutter={[0, 18]}>
      <Col span={24}>
        <ActivityFeedInput queries={queries} />
      </Col>
    </Row>
    <Typography.Title level={5}>{t("Activity Feed")}</Typography.Title>
    <Row gutter={[0, 18]}>
      <InfiniteScroll
        className="w-full"
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<Loading key={-1} fullHeight={false} />}
      >
        {activityFeedItems.map((activityFeedItem, i) => (
          <Col span={24} key={i}>
            <ActivityFeedItem
              activityFeedItem={activityFeedItem}
              queries={queries}
            />
          </Col>
        ))}
      </InfiniteScroll>
    </Row>
  </>
);

ActivityFeed.propTypes = {
  t: PropTypes.func,
  activityFeedItems: PropTypes.array,
  queries: PropTypes.object,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
};

ActivityFeed.defaultProps = {
  activityFeedItems: [],
};

export default withTranslation()(ActivityFeed);
