import React from "react";
import PropTypes from "prop-types";
import { Row, Col, List, Avatar, Typography } from "antd";
//import { Test } from './LeaderboardList.styles';

const LeaderboardList = ({ users }) => (
  <Row>
    <Col span={24}>
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={users}
        renderItem={(user, i) =>
          !!user && (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      color: "white",
                      backgroundColor: "transparent",
                      border: "1px solid rgba(255,255,255,0.2)",
                    }}
                    size={30}
                  >
                    {i + 1}
                  </Avatar>
                }
                title={
                  <Row align="middle">
                    <Col>
                      <Typography.Title
                        level={5}
                        className="m-0 relative top-1"
                      >
                        {user.nickname}
                        &nbsp; +{user.ranking?.ratings}
                      </Typography.Title>
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )
        }
      ></List>
    </Col>
  </Row>
);

LeaderboardList.propTypes = {
  users: PropTypes.array,
};

LeaderboardList.defaultProps = {
  users: [],
};

export default LeaderboardList;
