import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import "moment/locale/es";
import Moment from "react-moment";

// the translations
// (tip move them in a JSON file and import them)

let language = "en";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  })
  .then(() => {
    Moment.globalLocale = i18n.language;
    Moment.globalMoment = moment;
    language = i18n.language;
  });

export const getLanguage = () => {
  return language;
};

export default i18n;
