import React, { useContext } from "react";
import { loader } from "graphql.macro";
import AuthContext from "context/AuthContext";
import Query from "../components/data/Query";
import Navbar from "../components/clan/Navbar/index";

const NavbarContainer = (props) => {
  const { user, currentClan } = useContext(AuthContext);
  const countUnseenNotificationsQuery = loader(
    "queries/notifications/countUnseenNotifications.graphql"
  );

  const queries = {
    findNotifications: {
      query: countUnseenNotificationsQuery,
      variables: {
        userId: user?.id,
        clanId: currentClan?.id,
      },
    },
  };

  return currentClan?.id && user?.id ? (
    <Query
      {...queries["findNotifications"]}
      pollInterval={5000}
      allowLoading={false}
    >
      {({ data: { unseen_count } = { unseen_count: 0 } }) => (
        <Navbar
          {...props}
          unseenNotificationsCount={unseen_count?.aggregate?.count}
        />
      )}
    </Query>
  ) : (
    <Navbar {...props} />
  );
};

export default NavbarContainer;
