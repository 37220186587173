import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Statistic, Typography } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import { green, red } from "@ant-design/colors";
import { MiniArea } from "ant-design-pro/lib/Charts";
import moment from "moment";

const ICONS = {
  RM: "ra-archer",
  RM_TEAM: "ra-heavy-shield",
  KLAN: "ra-daggers",
};

const PersonalStats = ({ t, personalStats }) => {
  return Object.keys(personalStats).length > 0 ? (
    <Row gutter={[0, 10]}>
      {Object.keys(personalStats).map((type, i) => {
        const min =
          Math.min.apply(
            this,
            personalStats[type].ratingHistory.map((r) => r.rating)
          ) - 10;
        const ratingHistory = personalStats[type].ratingHistory.map((r) => ({
          x: moment.unix(r.timestamp).format("YYYY-MM-DD"),
          y: r.rating,
        }));
        return (
          !!personalStats[type].rank && (
            <Col span={24} key={i}>
              <Card
                size="small"
                title={
                  <>
                    <span
                      className={`ra ${ICONS[type]} text-xl relative top-1 mr-1`}
                    />
                    {t(type)}
                  </>
                }
                hoverable={true}
                className={"ant-card-with-opacity"}
              >
                <Row gutter={[5, 0]} justify="space-around">
                  <Col>
                    <Statistic
                      title={t("Rating")}
                      value={personalStats[type].rating}
                    />
                  </Col>
                  <Col>
                    <Statistic
                      title={t("Streak")}
                      prefix={
                        personalStats[type].streak > 0 ? (
                          <PlusOutlined type="success" />
                        ) : (
                          personalStats[type].streak < 0 && (
                            <MinusOutlined type="danger" />
                          )
                        )
                      }
                      valueStyle={
                        personalStats[type].streak > 0
                          ? { color: green.primary }
                          : personalStats[type].streak < 0
                          ? { color: red.primary }
                          : {}
                      }
                      value={Math.abs(personalStats[type].streak)}
                    />
                  </Col>
                  <Col>
                    <Statistic
                      title={t("Matches")}
                      value={personalStats[type].matches}
                    />
                  </Col>
                  <Col>
                    <Statistic
                      title={t("Wins")}
                      value={personalStats[type].wins}
                    />
                  </Col>
                </Row>
                {!!ratingHistory && ratingHistory.length > 0 && (
                  <Row>
                    <Col span={24}>
                      <div
                        style={{
                          height: 45 * 1.5,
                          marginTop: -(45 * 1.5) + 45,
                        }}
                      >
                        <MiniArea
                          line
                          height={45}
                          scale={{
                            y: {
                              min,
                            },
                          }}
                          data={ratingHistory}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
          )
        );
      })}
    </Row>
  ) : (
    <Row justify="center" className="w-full text-center">
      <Col>
        <Typography.Text>{t("No_stats_for_now")}</Typography.Text>
      </Col>
    </Row>
  );
};

PersonalStats.propTypes = {
  t: PropTypes.func,
  personalStats: PropTypes.object,
};

PersonalStats.defaultProps = {
  personalStats: {},
};

export default withTranslation()(PersonalStats);
