import { ServiceObject, ResultSuccess } from "simple-service-object";
import axios from "axios";
import moment from "moment";

export default class GetLastMatch extends ServiceObject {
  defaultResultData() {
    return { lastMatch: null, gameState: null, lastMatchAt: null };
  }

  async perform(steamId) {
    const {
      data: { last_match: lastMatch },
    } = await axios.get(
      `https://aoe2.net/api/player/lastmatch?game=aoe2de&steam_id=${steamId}&salt=${
        window.Math.random() * 1000000000000000
      }`
    );

    const gameState = !lastMatch.started
      ? "opened"
      : !lastMatch.finished
      ? "started"
      : "finished";

    const lastMatchAtUnix =
      lastMatch.finished || lastMatch.started || lastMatch.opened;
    const lastMatchAt = moment.unix(lastMatchAtUnix).toString();

    return new ResultSuccess({
      lastMatch,
      lastMatchId: lastMatch.match_id,
      lastMatchAt,
      lastMatchAtUnix,
      gameState,
    });
  }
}
