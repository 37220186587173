import React from "react";
import { withTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { Typography, Row, Col } from "antd";

const Error = ({ t }) => (
  <Row justify="center" align="middle" className="h-full">
    <Col>
      <Typography.Title>{t("Something went wrong")}</Typography.Title>
    </Col>
  </Row>
);

Error.propTypes = {
  t: PropTypes.func,
};

Error.defaultProps = {};

export default withTranslation()(Error);
