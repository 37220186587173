import React, { useContext } from "react";
import { Upload, Image, Row, Col } from "antd";
import { Formik } from "formik";
import AuthContext from "context/AuthContext";
import axiosClient from "../../../utils/axios";
import { isEmpty } from "lodash/isEmpty";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import Loading from "components/application-state/Loading";
import { UserOutlined } from "@ant-design/icons";
import { getFileUrl } from "utils/files";
// import PropTypes from 'prop-types';
//import { Test } from './ProfileForm.styles';

const ProfileForm = () => {
  const { user, token, currentClan, setUser } = useContext(AuthContext);

  const updateUserQuery = loader("queries/users/updateUser.graphql");

  const [updateUser] = useMutation(updateUserQuery);

  const validate = (values) => {
    const errors = {};
    let required = [];
    required.forEach((field) => {
      if (!values[field] || isEmpty(values[field].toString().trim()))
        errors[field] = "Requerido";
    });

    return errors;
  };

  const onSubmit = async (
    values,
    { setSubmitting, validateForm, setFieldValue }
  ) => {
    try {
      validateForm();
      setSubmitting(true);

      const formData = new FormData();
      let file = values.avatar;

      if (!!file) {
        formData.append("files", file);
        setFieldValue("fileLoading", true);
        const { data } = await axiosClient.post("/files/upload", formData, {
          headers: {
            "X-Path": "/uploads/",
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        file = {
          ...data[0],
          id: values.avatar?.id,
          clan_id: currentClan.id,
          object_type: "UserAvatar",
          object_id: values.id,
        };
      }

      let {
        data: {
          update_users_by_pk: newUser,
          insert_upload_files_one: newAvatar,
        },
      } = await updateUser({
        variables: {
          id: values.id,
          file,
          user: Object.assign({}),
        },
      });

      let userTmp = Object.assign(
        !!newUser && Object.keys(newUser).length > 0
          ? { ...newUser }
          : { ...user },
        !!newAvatar && Object.keys(newAvatar).length > 0
          ? { avatar: newAvatar }
          : {}
      );
      setUser(userTmp);
    } catch (err) {
      // TODO: Delete files
      console.error(err);
    } finally {
      setFieldValue("fileLoading", false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        id: user?.id,
        avatar: user?.avatar,
      }}
      enableReinitialize
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Row justify="center">
          <Col>
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              headers={{ "X-Path": "/uploads/" }}
              showUploadList={false}
              beforeUpload={(file) => {
                let newFile = file;
                if (file.type.includes("image/")) {
                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onloadend = () => {
                    newFile.url = reader.result;
                    const avatar = newFile;
                    if (!!values.avatar?.id) {
                      avatar["id"] = values.avatar?.id;
                    }
                    setFieldValue("avatar", avatar);
                    submitForm();
                  };
                }
                return false;
              }}
            >
              {values.fileLoading ? (
                <Loading compact />
              ) : !!user.avatar ? (
                <Image preview={false} src={getFileUrl(user.avatar)} />
              ) : (
                <UserOutlined style={{ fontSize: 40 }} />
              )}
            </Upload>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

ProfileForm.propTypes = {
  // bla: PropTypes.string,
};

ProfileForm.defaultProps = {
  // bla: 'test',
};

export default ProfileForm;
