import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Spin } from "antd";

const Loading = ({ isLoading, compact }) =>
  isLoading && (
    <Row justify="center" align="middle" className={compact ? "" : "h-full"}>
      <Col>
        <Spin />
      </Col>
    </Row>
  );

Loading.propTypes = {
  isLoading: PropTypes.bool,
  compact: PropTypes.bool,
};

Loading.defaultProps = {
  isLoading: true,
  compact: true,
};

export default Loading;
