import React, { useContext } from "react";
import Query from "../components/data/Query";
import { loader } from "graphql.macro";
import AuthContext from "context/AuthContext";
import Stories from "../components/clan/main/Stories/Stories";

const StoriesContainer = () => {
  const { currentMembers: users = [] } = useContext(AuthContext);

  const findMatchesQuery = loader("queries/matches/findMatches.graphql");

  const queries = {
    findMatches: {
      query: findMatchesQuery,
      variables: {
        where: {
          _and: {
            finished: {
              _is_null: false,
            },
            _or: users.map((user) => ({
              players: {
                _contains: [{ profile_id: parseInt(user.provider_id_alt) }],
              },
            })),
          },
        },
      },
    },
  };

  return (
    <Query {...queries["findMatches"]}>
      {({ data: { matches, factions } = { matches: [], factions: [] } }) => (
        <Stories matches={matches} factions={factions} users={users} />
      )}
    </Query>
  );
};

export default StoriesContainer;
