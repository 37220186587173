import { ServiceObject, ResultSuccess } from "simple-service-object";
import axios from "axios";
import xmlToJson from "../../utils/xmlToJson";

class GetSteamProfile extends ServiceObject {
  defaultResultData() {
    return { summary: {}, onlineState: false };
  }

  async perform(steamId) {
    const endpoint =
      process.env.REACT_APP_AUTH_HOST +
      `/proxy/https://steamcommunity.com/profiles/${steamId}?xml=1&salt=${
        window.Math.random() * 1000000000000000
      }`;
    const result = await axios.get(endpoint);
    var XmlNode = new DOMParser().parseFromString(result.data, "text/xml");
    const { profile } = xmlToJson(XmlNode);
    const { onlineState = "offline" } = profile;

    return new ResultSuccess({ profile, onlineState });
  }
}

export default GetSteamProfile;
