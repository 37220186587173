import PersonalStats from "components/clan/main/PersonalStats";
import AuthContext from "context/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import GetPersonalStats from "../services/core/GetPersonalStats";

const PersonalStatsContainer = () => {
  const { currentGame, user } = useContext(AuthContext);

  const [personalStats, setPersonalStats] = useState({});

  useEffect(() => {
    if (!!user) {
      (async () => {
        const result = await GetPersonalStats.perform(currentGame, user);
        setPersonalStats(result.personalStats);
      })();
    }
  }, [setPersonalStats, currentGame, user]);

  return <PersonalStats personalStats={personalStats} />;
};

export default PersonalStatsContainer;
