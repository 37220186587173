import React from "react";
import { Badge, Button, Col, Menu, Row, Tooltip } from "antd";
import { HomeOutlined, BellOutlined } from "@ant-design/icons";
import AuthContext from "context/AuthContext";
import logo from "assets/img/logo.png";
import LoginButton from "components/LoginButton";
import styles from "./Navbar.module.scss";
import { withTranslation } from "react-i18next";
import UserAvatar from "components/common/UserAvatar";
import { PropTypes } from "prop-types";

const Navbar = ({
  t,
  unseenNotificationsCount,
  setShowProfileMenu,
  setShowNotifications,
}) => (
  <AuthContext.Consumer>
    {({ isAuthenticated, user }) => (
      <Row>
        <Col span={24}>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["home"]}
            className={styles.menu}
          >
            <Menu.Item key="home">
              <HomeOutlined
                style={{
                  fontSize: "1.5rem",
                  position: "relative",
                  top: "0.2rem",
                }}
              ></HomeOutlined>
            </Menu.Item>
            {/* <Menu.Item onClick={() => {
              //less variables that will be used here must be declared in themeVariables on config-overrides.js
              window.less
                .modifyVars({
                    "@primary-color": "#52c41a"
                })
                .then(() => {
                    // Color changed!
                })
                .catch(error => {
                    console.error(error);
                });
            }} key="players">{t('Players')}</Menu.Item>
            <Menu.Item key="members">{t('Leaderboard')}</Menu.Item>
            <Menu.Item key="matches">{t('Matches')}</Menu.Item> */}
            <Menu.Item className={styles["menu-disabled"]}>
              <div className={styles.logo}>
                <img src={logo} alt="ReBeL" className={styles.img} />
              </div>
            </Menu.Item>
            {/* <Menu.Item key="tournaments">{t('Tournaments')}</Menu.Item>
            <Menu.Item key="learn">{t('University')}</Menu.Item>
            <Menu.Item key="play">{t('Challenges')}</Menu.Item> */}
            {!!isAuthenticated && !!user ? (
              <>
                <Menu.Item key="user" className={styles["menu-disabled"]}>
                  <Row gutter={[10, 0]}>
                    <Col>
                      <Tooltip title={t("Profile")}>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowProfileMenu(true)}
                        >
                          <UserAvatar
                            user={user}
                            className="mr-2"
                            previewImage={false}
                          />
                          {user.nickname}
                        </span>
                      </Tooltip>
                    </Col>
                    <Col>
                      <Tooltip title={t("Notifications")}>
                        <Badge count={unseenNotificationsCount} offset={[-10]}>
                          <Button
                            shape="circle"
                            type="primary"
                            onClick={() => setShowNotifications(true)}
                            icon={
                              <BellOutlined
                                size="lg"
                                className="relative"
                                style={{ fontSize: 18, top: 2, left: 5 }}
                              />
                            }
                          ></Button>
                        </Badge>
                      </Tooltip>
                    </Col>
                  </Row>
                </Menu.Item>
              </>
            ) : (
              <Menu.Item key="login" className={styles["menu-disabled"]}>
                <LoginButton />
              </Menu.Item>
            )}
          </Menu>
        </Col>
      </Row>
    )}
  </AuthContext.Consumer>
);

Navbar.propTypes = {
  t: PropTypes.func,
  unseenNotificationsCount: PropTypes.number,
};

Navbar.defaultProps = {
  unseenNotificationsCount: 0,
};

export default withTranslation()(Navbar);
