import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Statistic, Typography } from "antd";
import moment from "moment";
import AuthContext from "../../../../context/AuthContext";
import { withTranslation } from "react-i18next";
import { Line } from "@ant-design/charts";
import { green } from "@ant-design/colors";
import groupBy from "lodash/groupBy";
//import { Test } from './ClanStats.styles';

const ClanStats = ({ t, ratings, matchesCount }) => {
  const { currentClan, currentMembers } = useContext(AuthContext);

  const ratingByRanks = groupBy(ratings, (rating) => rating.rank_type);

  let data = {},
    min = {},
    max = {};

  Object.keys(ratingByRanks).forEach((rank_type) => {
    ratingByRanks[rank_type].forEach((rating) => {
      if (!min[rank_type] || rating.rating < min[rank_type])
        min[rank_type] = rating.rating;
      if (!max[rank_type] || rating.rating > max[rank_type])
        max[rank_type] = rating.rating;
    });

    data[rank_type] = ratings
      .filter((r) => r.rank_type === rank_type)
      .map((rating) => ({
        week: moment(rating.week).format("YYYY-MM-DD"),
        rank_type: rating.rank_type,
        value: rating.rating,
      }));
  });

  const options = {
    height: 100,
    xField: "week",
    yField: "value",
    xAxis: { type: "time" },
    smooth: true,
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          size="small"
          title={
            <>
              <span className={`ra ra-daggers text-xl relative top-1 mr-1`} />
              {currentClan.name} ({t("Clan stats")} )
            </>
          }
          hoverable={true}
          className={"ant-card-with-opacity"}
        >
          <Row gutter={[5, 0]} justify="space-around">
            {Object.keys(ratingByRanks).map((rank_type) => {
              const rating =
                ratingByRanks[rank_type]?.[ratingByRanks[rank_type]?.length - 1]
                  ?.rating;
              return (
                <Col className="text-center" key={rank_type}>
                  <Statistic
                    title={rank_type.replace("_", " ")}
                    value={rating && `+${rating}`}
                    valueStyle={{
                      color: green.primary,
                    }}
                  />
                </Col>
              );
            })}
            <Col className="text-center">
              <Statistic title={t("Matches")} value={matchesCount} />
            </Col>
            <Col className="text-center">
              <Statistic title={t("Members")} value={currentMembers.length} />
            </Col>
          </Row>
          <Row justify="center" gutter={[0, 10]}>
            {Object.keys(ratingByRanks).map(
              (rank_type) =>
                !!data[rank_type] &&
                data[rank_type].length > 0 && (
                  <Fragment key={rank_type}>
                    <Col>
                      <Typography.Text>
                        {rank_type.replace("_", " ")}
                      </Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Line
                        {...options}
                        data={data[rank_type]}
                        meta={{
                          value: {
                            alias: rank_type.replace("_", " "),
                            formatter: (v) => `+${v.toLocaleString()}`,
                          },
                        }}
                        yAxis={{
                          legend: {
                            layout: "horizontal",
                            position: "right",
                          },
                          title: {
                            autoRotate: true,
                          },
                          // label: {
                          //   formatter: (v) => `+${v}`
                          // },
                          min: min[rank_type] - 5,
                          max: max[rank_type] + 5,
                        }}
                      />
                    </Col>
                  </Fragment>
                )
            )}
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

ClanStats.propTypes = {
  ratings: PropTypes.array,
  matchesCount: PropTypes.number,
  t: PropTypes.func,
};

ClanStats.defaultProps = {
  ratings: [],
  matchesCount: 0,
};

export default withTranslation()(ClanStats);
