import React from "react";
import AuthContext from "../context/AuthContext";
import { Redirect } from "react-router-dom";

const Auth = ({ children }) => (
  <AuthContext.Consumer>
    {(context) => (context.isAuthenticated ? children : <Redirect to="/" />)}
  </AuthContext.Consumer>
);

export default Auth;
