import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import { button } from "./EditActivityFeedItem.module.scss";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { PropTypes } from "prop-types";

const deleteActivityQuery = loader("queries/activities/deleteActivity.graphql");

const EditActivityFeedItem = ({ t, id, queries, setIsEditing }) => {
  const [deleteActivity, deleteActivityState] = useMutation(
    deleteActivityQuery,
    {
      refetchQueries: Object.values(queries),
    }
  );

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item>
            <Button
              type="link"
              className={button}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              {t("Edit")}
            </Button>
          </Menu.Item>
          <Menu.Item danger>
            <Button
              type="link"
              className={button}
              loading={deleteActivityState.loading}
              onClick={() => {
                deleteActivity({
                  variables: { id },
                });
              }}
            >
              {t("Delete")}
            </Button>
          </Menu.Item>
        </Menu>
      }
    >
      <Button type="dashed" icon={<EditOutlined />} />
    </Dropdown>
  );
};

EditActivityFeedItem.propTypes = {
  t: PropTypes.func,
  id: PropTypes.number,
  queries: PropTypes.object,
  setIsEditing: PropTypes.func,
};

EditActivityFeedItem.defaultProps = {};

export default withTranslation()(EditActivityFeedItem);
