class PersonalStatHistory {
  constructor({ type, rating, wins, losses, streak, drops, timestamp }) {
    this.type = type;
    this.rating = rating;
    this.streak = streak;
    this.wins = wins;
    this.losses = losses;
    this.drops = drops;
    this.timestamp = timestamp;
  }

  toJson() {
    return {
      type: this.type,
      rating: this.rating,
      streak: this.streak,
      wins: this.wins,
      losses: this.losses,
      drops: this.drops,
      timestamp: this.timestamp,
    };
  }
}

export default PersonalStatHistory;
