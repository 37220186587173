import React from "react";
import PropTypes from "prop-types";
import { List, Row, Col, Button, Typography } from "antd";
import { withTranslation } from "react-i18next";
import Moment from "react-moment";
import { joinAoe2DEGame } from "../../../../utils/game";
import UserAvatar from "components/common/UserAvatar";
//import { Test } from './MemberState.styles';

const MemberState = ({ t, user }) => {
  return (
    <List.Item>
      <Row className="w-full" align="middle">
        <Col flex="auto">
          <List.Item.Meta
            avatar={<UserAvatar user={user} size="small" iconFontSize={16} />}
            title={user.nickname}
            description={
              <Typography.Text
                type={user.isPlaying || user.isConnected ? "success" : ""}
              >
                {!!user.providerState &&
                  t(
                    "Provider States." +
                      (user.isPlaying ? "in-game" : user.providerState)
                  )}
                &nbsp;
                {!!user.lastMatchAt && (
                  <>
                    ({t("Last play")}: &nbsp;
                    <Moment fromNow>{user.lastMatchAt}</Moment>)
                  </>
                )}
              </Typography.Text>
            }
          />
        </Col>
        {user.isPlaying && (
          <Col xs={0} className="lg:block">
            <Button
              type="primary"
              size="small"
              onClick={() => joinAoe2DEGame(user.lastMatchId)}
            >
              {user.gameState === "started"
                ? t("Join as spectator")
                : t("Join game")}
            </Button>
          </Col>
        )}
      </Row>
    </List.Item>
  );
};

MemberState.propTypes = {
  user: PropTypes.object,
};

MemberState.defaultProps = {
  user: {},
};

export default withTranslation()(MemberState);
