import { getPersonalStats } from "../../queries/aoe2/index";
const { ServiceObject, ResultSuccess } = require("simple-service-object");

class GetPersonalStats extends ServiceObject {
  defaultResultData() {
    return { personalStats: {} };
  }

  async perform(game, user) {
    const personalStats = await getPersonalStats({
      game: "aoe2de",
      playerId: user.provider_id,
      types: game.rank_types.map((rank_type) => rank_type.name),
    });

    return new ResultSuccess({ personalStats });
  }
}

export default GetPersonalStats;
