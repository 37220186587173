import React, { useState, useEffect } from "react";
import AuthContext from "../context/AuthContext";
import MainRoutes from "../routes/MainRoutes";
import axios from "axios";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import Loading from "./application-state/Loading";
import Error from "./application-state/Error";

const findClansQuery = loader("queries/clans/findClans.graphql");

const Main = () => {
  const [currentClan, setCurrentClan] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);
  const [currentMembers, setCurrentMembers] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [token, setToken] = useState();
  const [user, setUser] = useState();

  const { data, loading, error } = useQuery(findClansQuery, {
    variables: {
      domain: "rebel.klan.gg",
    },
  });

  useEffect(() => {
    if (!!data?.clans && data?.clans.length > 0) {
      setCurrentClan(data.clans[0]);
      setCurrentGame(data.clans[0].game);
      setCurrentMembers(data.clans[0]?.clans_users?.map((cu) => cu.user) || []);
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      try {
        const user = await axios.post(
          `${process.env.REACT_APP_AUTH_HOST}/login/success`,
          {},
          {
            withCredentials: "include",
          }
        );
        setIsAuthenticated(true);
        setUser(user.data);
        localStorage.setItem("token", user.data.token);
        setToken(user.data.token);
      } catch (err) {}
    })();
  }, []);

  if (!!loading) {
    return (
      <div className="h-screen">
        <Loading />
      </div>
    );
  }

  if (!!error || !currentClan) {
    return (
      <div className="h-screen">
        <Error />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentClan,
        currentGame,
        currentMembers,
        isAuthenticated,
        user,
        setUser,
        token,
      }}
    >
      <MainRoutes />
    </AuthContext.Provider>
  );
};

export default Main;
