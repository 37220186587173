import React from "react";
import { Menu } from "antd";
import { withTranslation } from "react-i18next";
import ProfileForm from "../user/ProfileForm";

const Profile = ({ t }) => {
  const logout = () => {
    window.open(`${process.env.REACT_APP_AUTH_HOST}/logout`, "_self");
  };
  return (
    <Menu theme="dark">
      <ProfileForm />
      <Menu.Item onClick={() => logout()}>{t("Sign out")}</Menu.Item>
    </Menu>
  );
};

export default withTranslation()(Profile);
