import ActivityFeed from "components/clan/main/activity-feeds/ActivityFeed/ActivityFeed";
import React, { useContext } from "react";
import { loader } from "graphql.macro";
import Query from "../components/data/Query";
import AuthContext from "context/AuthContext";

const ActivityFeedContainer = () => {
  const {
    currentClan: { id: clanId },
  } = useContext(AuthContext);

  const findActivitiesQuery = loader(
    "queries/activities/findActivities.graphql"
  );

  const queries = {
    findActivities: {
      query: findActivitiesQuery,
      variables: {
        clanId,
        limit: 20,
      },
    },
  };

  return (
    <Query {...queries["findActivities"]}>
      {(
        {
          data: {
            activities,
            activities_aggregate: {
              aggregate: { count },
            },
          } = {
            activities: [],
            activities_aggregate: { aggregate: { count: 0 } },
          },
        },
        state,
        { fetchMore }
      ) => {
        return (
          <ActivityFeed
            activityFeedItems={activities}
            queries={queries}
            loadMore={() => {
              fetchMore({
                variables: {
                  offset: activities.length,
                  ...queries["findActivities"]["variables"],
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    activities: [
                      ...prev.activities,
                      ...fetchMoreResult.activities,
                    ],
                  });
                },
              });
            }}
            hasMore={activities.length < count}
          />
        );
      }}
    </Query>
  );
};

export default ActivityFeedContainer;
