import React, { useContext } from "react";
import Leaderboard from "../components/clan/main/Leaderboard/Leaderboard";
import AuthContext from "../context/AuthContext";

const LeaderboardContainer = () => {
  const { currentMembers = [] } = useContext(AuthContext);

  const topMembers = currentMembers
    .sort((a, b) => b.ranking?.ratings - a.ranking?.ratings)
    .slice(0, 20);

  return <Leaderboard users={topMembers} />;
};

export default LeaderboardContainer;
