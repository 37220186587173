import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import ClanRoutes from "./ClanRoutes";

const MainRoutes = () => (
  <Router>
    <Switch>
      <Redirect exact path="/" to="/c" />
      <Route path="/c" component={ClanRoutes} />
    </Switch>
  </Router>
);

export default MainRoutes;
