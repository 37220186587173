import React, { useContext } from "react";
import { loader } from "graphql.macro";
import AuthContext from "context/AuthContext";
import Query from "../components/data/Query";
import Notifications from "../components/clan/Notifications/index";

const NotificationsContainer = () => {
  const { user, currentClan } = useContext(AuthContext);
  const findNotificationsQuery = loader(
    "queries/notifications/findNotifications.graphql"
  );

  const queries = {
    findNotifications: {
      query: findNotificationsQuery,
      variables: {
        userId: user?.id,
        clanId: currentClan?.id,
      },
    },
  };

  return (
    <Query {...queries["findNotifications"]} allowLoading={false}>
      {(
        {
          data: { notifications, total_count } = {
            notifications: [],
          },
        },
        state,
        { fetchMore }
      ) => (
        <Notifications
          notifications={notifications}
          hasMore={notifications.length < (total_count?.aggregate?.count || 0)}
          loadMore={() => {
            fetchMore({
              variables: {
                offset: notifications.length,
                ...queries["findNotifications"]["variables"],
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  notifications: [
                    ...prev.notifications,
                    ...fetchMoreResult.notifications,
                  ],
                });
              },
            });
          }}
        />
      )}
    </Query>
  );
};

export default NotificationsContainer;
