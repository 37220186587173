import AuthContext from "context/AuthContext";
import React, { useContext } from "react";
import Query from "../components/data/Query";
import { loader } from "graphql.macro";
import ClanStats from "../components/clan/main/ClanStats";

const ClanStatsContainer = () => {
  const { currentMembers: users } = useContext(AuthContext);
  const findRatingsByWeekQuery = loader(
    "queries/ratings/findRatingsByWeek.graphql"
  );
  const countMatchesQuery = loader("queries/matches/countMatches.graphql");

  const queries = {
    findRatingsByWeek: {
      query: findRatingsByWeekQuery,
      variables: {
        user_ids: `{${users.map((u) => u.id).join(",")}}`,
        rank_type: "ALL",
      },
    },
    countMatches: {
      query: countMatchesQuery,
      variables: {
        where: {
          _and: {
            finished: {
              _is_null: false,
            },
            _or: users.map((user) => ({
              players: {
                _contains: [{ profile_id: parseInt(user.provider_id_alt) }],
              },
            })),
          },
        },
      },
    },
  };

  return (
    <Query {...queries["findRatingsByWeek"]}>
      {({
        data: { last_ratings_by_week_by_users: ratings } = { ratings: [] },
      }) => (
        <Query {...queries["countMatches"]}>
          {({
            data: {
              matches_aggregate: {
                aggregate: { count },
              },
            } = { ratings: [] },
          }) => <ClanStats ratings={ratings} matchesCount={count} />}
        </Query>
      )}
    </Query>
  );
};

export default ClanStatsContainer;
