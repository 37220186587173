import GetPersonalStatsAoe2De from "../aoe2de/GetPersonalStats";
const { ServiceObject, ResultSuccess } = require("simple-service-object");

class GetPersonalStats extends ServiceObject {
  defaultResultData() {
    return { personalStats: {} };
  }

  async perform(game, user) {
    let personalStats;
    switch (game.code) {
      // TODO: Could be in realtime? if not ask to graphql the sync
      case "aoe2de": {
        personalStats = (await GetPersonalStatsAoe2De.perform(game, user))
          .personalStats;
        break;
      }
      default: {
      }
    }

    return new ResultSuccess({ personalStats });
  }
}

export default GetPersonalStats;
