import React, { useState } from "react";
import { Comment } from "antd";
import Moment from "react-moment";
import ActivityFeedItemContent from "../ActivityFeedItemContent";
import PropTypes from "prop-types";
import UserAvatar from "components/common/UserAvatar";
//import { Test } from './ActivityFeedItemComment.styles';

const ActivityFeedItemComment = ({ activityFeedItem, queries }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Comment
      // actions={[
      //   <span key="comment-nested-reply-to">Reply to</span>,
      // ]}
      author={
        <>
          {activityFeedItem.user?.nickname}
          &nbsp; - &nbsp;
          <i>
            <Moment fromNow>{activityFeedItem.created_at}</Moment>
          </i>
        </>
      }
      avatar={<UserAvatar user={activityFeedItem.user} />}
      content={
        <ActivityFeedItemContent
          activityFeedItem={activityFeedItem}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          queries={queries}
        />
      }
    ></Comment>
  );
};

ActivityFeedItemComment.propTypes = {
  activityFeedItem: PropTypes.object,
  queries: PropTypes.object,
};

ActivityFeedItemComment.defaultProps = {};

export default ActivityFeedItemComment;
