import React from "react";
import { useQuery } from "@apollo/client";
import Loading from "components/application-state/Loading";
import Error from "components/application-state/Error";

const Query = ({
  children,
  query,
  variables,
  pollInterval,
  allowLoading = true,
  allowErrors = true,
}) => {
  const { data, loading, error, fetchMore } = useQuery(
    query,
    Object.assign({ variables }, pollInterval ? { pollInterval } : {})
  );

  if (allowLoading && loading) return <Loading />;
  if (allowErrors && error) return <Error />;
  return children
    ? children({ data }, { loading, error }, { fetchMore })
    : null;
};

export default Query;
