import React, { useContext } from "react";
import PropTypes from "prop-types";
import { CustomGif } from "./GiphySearch.styles";
import { Row, Col, Image } from "antd";
import poweredGiphy from "assets/img/third_parties/powered_giphy.png";

import {
  Grid, // our UI Component to display the results
  SearchBar, // the search bar the user will type into
  SearchContext, // the context that wraps and connects our components
  SearchContextManager, // the context manager, includes the Context.Provider
  SuggestionBar, // an optional UI component that displays trending searches and channel / username results
} from "@giphy/react-components";

// define the components in a separate function so we can
// use the context hook. You could also use the render props pattern
const Components = (props) => {
  const { fetchGifs, searchKey } = useContext(SearchContext);
  return (
    <>
      <SearchBar />
      <SuggestionBar />
      <CustomGif>
        <Grid
          key={searchKey}
          columns={3}
          width={500}
          fetchGifs={fetchGifs}
          noLink
          hideAttribution
          {...props}
        />
      </CustomGif>
      <Row justify="center">
        <Col>
          <Image className="mt-5" src={poweredGiphy} />
        </Col>
      </Row>
    </>
  );
};

// the search experience consists of the manager and its child components that use SearchContext
const SearchExperience = (props) => (
  <SearchContextManager apiKey={"ERLZVRGNv2gHMJYPzJEDOjNWrZBfAehE"}>
    <Components {...props} />
  </SearchContextManager>
);

SearchExperience.propTypes = {
  onGifClick: PropTypes.func,
};

SearchExperience.defaultProps = {
  onGifClick: () => {},
};

export default SearchExperience;
