import React from "react";
import { Route, Switch } from "react-router-dom";
import Landing from "../components/clan/Landing";
import Main from "../components/clan/Main";
import Auth from "./Auth";

const ClanRoutes = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={Landing} />
    <Auth>
      <Route path={`${match.path}/main`} component={Main} />
    </Auth>
  </Switch>
);

export default ClanRoutes;
