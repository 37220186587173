import React, { useContext } from "react";
import { Row, Col, Typography } from "antd";
import Moment from "react-moment";
import EditActivityFeedItem from "../EditActivityFeedItem/index";
import AuthContext from "context/AuthContext";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import UserAvatar from "components/common/UserAvatar";
//import { Test } from './ActivityFeedItemTitle.styles';

const ActivityFeedItemTitle = ({
  t,
  activityFeedItem,
  setIsEditing,
  queries,
}) => {
  const { user } = useContext(AuthContext);

  return (
    <Row gutter={[5, 0]} align="middle">
      <Col flex="auto">
        <Typography.Text>
          <UserAvatar
            user={activityFeedItem.user}
            size="medium"
            className={"mr-2"}
            iconFontSize={20}
          />
          {t("Activity Feed Item " + activityFeedItem.type, {
            username: activityFeedItem.user.nickname,
          })}
        </Typography.Text>
      </Col>
      <Col>
        <Moment fromNow>{activityFeedItem.created_at}</Moment>
      </Col>
      <Col>
        {activityFeedItem.user.id === user.id && (
          <EditActivityFeedItem
            id={activityFeedItem.id}
            queries={queries}
            setIsEditing={setIsEditing}
          />
        )}
      </Col>
    </Row>
  );
};

ActivityFeedItemTitle.propTypes = {
  t: PropTypes.func,
  activityFeedItem: PropTypes.object,
  setIsEditing: PropTypes.func,
  queries: PropTypes.object,
};

ActivityFeedItemTitle.defaultProps = {};

export default withTranslation()(ActivityFeedItemTitle);
