import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Tabs } from "antd";
import { RiseOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
//import { Test } from './Leaderboard.styles';
import LeaderboardList from "../LeaderboardList";

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

const Leaderboard = ({ t, users }) => {
  const [currentTab, setCurrentTab] = useState(getRandomInt(2)?.toString());
  const [currentUsers, setCurrentUsers] = useState(users);

  useEffect(() => {
    if (currentTab === "0") {
      setCurrentUsers(users.slice(0, 10));
    } else {
      setCurrentUsers(users.slice(10, 20));
    }
  }, [currentTab, setCurrentUsers, users]);

  return (
    <Card
      title={
        <>
          <RiseOutlined className="mr-2" />
          {t("Top 10")}
        </>
      }
      className="ant-card-with-opacity ant-card-without-padding"
    >
      <Tabs
        tabBarStyle={{ paddingLeft: 30 }}
        defaultActiveKey={currentTab}
        onChange={(key) => setCurrentTab(key)}
      >
        <Tabs.TabPane tab={t("Gold")} key="0">
          <LeaderboardList users={currentUsers} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Silver")} key="1">
          <LeaderboardList users={currentUsers} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

Leaderboard.propTypes = {
  users: PropTypes.array,
  t: PropTypes.func,
};

Leaderboard.defaultProps = {
  users: [],
};

export default withTranslation()(Leaderboard);
