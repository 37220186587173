import axios from "axios";
import PersonalStat from "../../models/PersonalStat";
import PersonalStatHistory from "../../models/personalStatHistory";

const LEADERBOARD = {
  UNRANKED: 0,
  DM: 1,
  DM_TEAM: 2,
  RM: 3,
  RM_TEAM: 4,
};

export const getPersonalStats = async ({ game, playerId, types = [] }) => {
  try {
    switch (game) {
      case "aoe2de": {
        let result = {};
        for (let i = 0; i < types.length; ++i) {
          const type = types[i];
          const leaderboardResult = await axios.get(
            `https://aoe2.net/api/leaderboard?game=aoe2de&leaderboard_id=${LEADERBOARD[type]}&steam_id=${playerId}`
          );
          if (!!leaderboardResult.data?.leaderboard?.[0]) {
            const {
              rank,
              rating,
              previous_rating,
              highest_rating,
              streak,
              lowest_streak,
              highest_streak,
              games: matches,
              wins,
              losses,
              drops,
              last_match,
              last_match_time,
            } = leaderboardResult.data?.leaderboard?.[0];

            const ratingHistoryResult = await axios.get(
              `https://aoe2.net/api/player/ratinghistory?game=aoe2de&leaderboard_id=${LEADERBOARD[type]}&steam_id=${playerId}`
            );
            const ratingHistory = ratingHistoryResult?.data?.map(
              (rating) => new PersonalStatHistory({ ...rating, type })
            );

            const personalStat = new PersonalStat({
              type,
              rank,
              rating,
              previous_rating,
              highest_rating,
              streak,
              lowest_streak,
              highest_streak,
              matches,
              wins,
              losses,
              drops,
              last_match,
              last_match_time,
              ratingHistory,
            });

            result[type] = personalStat.toJson();
          }
        }
        return result;
      }
      default: {
      }
    }
  } catch (err) {
    console.error(err);
  }
};
