import React, { useContext, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Button, Divider, Avatar } from "antd";

import { withTranslation } from "react-i18next";
import ActivityFeedInput from "../ActivityFeedInput";
import AuthContext from "context/AuthContext";
import { CommentOutlined } from "@ant-design/icons";
import { ReactComponent as ConfettiOff } from "assets/svg/confetti-off.svg";
import { ReactComponent as ConfettiOn } from "assets/svg/confetti-on.svg";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import ActivityFeedItemContent from "../ActivityFeedItemContent";
import ActivityFeedItemTitle from "../ActivityFeedItemTitle";
import ActivityFeedItemComment from "../ActivityFeedItemComment/ActivityFeedItemComment";
import Confetti from "react-confetti";

const ActivityFeedItem = ({ t, activityFeedItem, queries }) => {
  const { user, currentClan } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const insertCelebrationQuery = loader(
    "queries/celebrations/insertCelebration.graphql"
  );
  const deleteCelebrationQuery = loader(
    "queries/celebrations/deleteCelebration.graphql"
  );

  const commentRef = useRef();

  const [insertCelebration] = useMutation(insertCelebrationQuery, {
    refetchQueries: Object.values(queries),
  });
  const [deleteCelebration] = useMutation(deleteCelebrationQuery, {
    refetchQueries: Object.values(queries),
  });

  const isCelebrated = activityFeedItem?.celebrations
    ?.map((c) => c.created_by)
    ?.includes(user?.id);

  const ConfettiIcon = isCelebrated ? ConfettiOn : ConfettiOff;

  const celebrationsCount =
    activityFeedItem?.celebrations_aggregate?.aggregate?.count;

  return (
    <Card
      size="small"
      type="inner"
      title={
        <>
          <ActivityFeedItemTitle
            activityFeedItem={activityFeedItem}
            queries={queries}
            setIsEditing={setIsEditing}
          />
        </>
      }
      className={"ant-card-with-opacity"}
    >
      <ActivityFeedItemContent
        activityFeedItem={activityFeedItem}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        queries={queries}
      />
      {!!celebrationsCount && celebrationsCount > 0 && (
        <div className="mt-5">
          <Avatar
            size={30}
            className="mr-2"
            style={{ backgroundColor: "var(--primary-color)" }}
            icon={
              <ConfettiOn
                width={17}
                height={17}
                className="relative"
                style={{ top: 3, left: 1 }}
              />
            }
          />
          {t("Celebrations count", { count: celebrationsCount })}
        </div>
      )}
      <Divider style={{ margin: 5 }} />
      <Row justify="space-around">
        <Col>
          {showConfetti && (
            <Confetti
              className="w-screen h-screen fixed"
              onConfettiComplete={() => setShowConfetti(false)}
              recycle={false}
              numberOfPieces={1000}
              initialVelocityY={-20}
            />
          )}
          <Button
            type={isCelebrated ? "ghost" : "text"}
            danger={isCelebrated}
            icon={
              <ConfettiIcon
                width={21}
                height={21}
                className={"mr-1"}
                style={{
                  marginTop: -5,
                  position: "relative",
                  top: 3,
                }}
              />
            }
            onClick={async (e) => {
              if (!isCelebrated) {
                setShowConfetti(true);
                insertCelebration({
                  variables: {
                    object: {
                      created_by: user.id,
                      clan_id: currentClan.id,
                      object_type: "Activity",
                      object_id: activityFeedItem.id,
                    },
                  },
                });
              } else {
                e.target.blur();
                deleteCelebration({
                  variables: {
                    where: {
                      created_by: { _eq: user.id },
                      clan_id: { _eq: currentClan.id },
                      object_type: { _eq: "Activity" },
                      object_id: { _eq: activityFeedItem.id },
                    },
                  },
                });
              }
            }}
          >
            {t("Celebrate")}
          </Button>
        </Col>
        <Col>
          <Button
            type="text"
            icon={<CommentOutlined />}
            onClick={() => {
              commentRef.current?.textarea?.focus();
              commentRef.current?.textarea?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }}
          >
            {t("Comment")}
          </Button>
        </Col>
      </Row>
      <Divider style={{ margin: 5 }} />
      <Row>
        {activityFeedItem.comments?.map((activityFeedItem, i) => (
          <Col span={24} key={i}>
            <ActivityFeedItemComment
              activityFeedItem={activityFeedItem}
              queries={queries}
            />
          </Col>
        ))}
      </Row>
      <Row justify="start">
        <Col span={24}>
          <ActivityFeedInput
            ref={commentRef}
            allowTitle={false}
            noBorder={true}
            parentActivityId={activityFeedItem.id}
            queries={queries}
          />
        </Col>
      </Row>
    </Card>
  );
};

ActivityFeedItem.propTypes = {
  t: PropTypes.func,
  activityFeedItem: PropTypes.object,
  queries: PropTypes.object,
};

ActivityFeedItem.defaultProps = {
  activityFeedItem: null,
};

export default withTranslation()(ActivityFeedItem);
