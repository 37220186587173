import React from "react";
import { Redirect } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import ClanLayout from "./ClanLayout";

import Hero from "./Hero";

const Landing = () => (
  <AuthContext.Consumer>
    {(context) =>
      context.isAuthenticated ? (
        <Redirect to="/c/main" />
      ) : (
        <ClanLayout>
          <Hero />
        </ClanLayout>
      )
    }
  </AuthContext.Consumer>
);

export default Landing;
