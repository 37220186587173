import { ServiceObject, ResultSuccess } from "simple-service-object";

import GetLastMatch from "../aoe2de/GetLastMatch";
import GetSteamProfile from "../steam/GetSteamProfile";

class GetUserState extends ServiceObject {
  defaultResultData() {
    return { user: null };
  }

  async perform(user, currentGame) {
    switch (currentGame) {
      case "aoe2de": {
        const {
          success: successGetLastMatch,
          gameState,
          lastMatchAt,
          lastMatchAtUnix,
          lastMatchId,
        } = await GetLastMatch.perform(user.provider_id);
        if (successGetLastMatch) {
          user = Object.assign({
            ...user,
            ...{ gameState, lastMatchAt, lastMatchAtUnix, lastMatchId },
          });
        }
        const {
          success: successGetSteamProfile,
          onlineState: providerState,
        } = await GetSteamProfile.perform(user.provider_id);
        if (successGetSteamProfile) {
          user = Object.assign({ ...user, ...{ providerState } });
        }
        break;
      }
      default: {
        break;
      }
    }
    user = Object.assign({
      ...user,
      ...{
        isPlaying:
          !!user.gameState &&
          (user.gameState === "started" || user.gameState === "opened"),
        isConnected: !!user.providerState && user.providerState !== "offline",
      },
    });
    return new ResultSuccess({ user });
  }
}

export default GetUserState;
