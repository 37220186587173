import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Popover, Row, Col, Image, Typography, Button } from "antd";
import { withTranslation } from "react-i18next";
import AuthContext from "../../../../context/AuthContext";
import { DownloadOutlined } from "@ant-design/icons";
//import { Test } from './PopoverMember.styles';

const PopoverMember = ({
  children,
  t,
  player,
  match,
  faction,
  profileIds,
  teamIds,
}) => {
  const { currentGame } = useContext(AuthContext);

  return (
    <Popover
      content={
        <Row>
          <Col span={24}>
            {player.won !== null && (
              <>
                {t("Result")}:&nbsp;
                {!!player.won ? (
                  <>
                    <span className={`ra ra-trophy mr-1`} />
                    {t("Won")}
                  </>
                ) : (
                  t("Lost")
                )}
              </>
            )}
          </Col>
          <Col span={24}>
            {t(`${currentGame.code}:factions.${player.civ}`, "") && (
              <>
                {t(`${currentGame.code}:faction`)}:{" "}
                {t(`${currentGame.code}:factions.${player.civ}`)}
              </>
            )}
          </Col>
          <Col span={24}>
            {!!t(
              `${currentGame.code}:slot.${teamIds.length}.${player.slot}`,
              ""
            ) && (
              <>
                {t(`Slot`)}:&nbsp;
                {t(`${currentGame.code}:slot.${teamIds.length}.${player.slot}`)}
              </>
            )}
          </Col>
          <Col>
            {!!player.rating && (
              <>
                {t("Rating change")}:&nbsp;
                {player.rating_change > 0 ? (
                  <Typography.Text type="success">
                    +{player.rating_change}
                  </Typography.Text>
                ) : (
                  <Typography.Text type="danger">
                    {player.rating_change}
                  </Typography.Text>
                )}
              </>
            )}
          </Col>
          <Col span={24}>
            {player.country !== null && (
              <>
                {t("Country")}:&nbsp;
                {player.country}
              </>
            )}
          </Col>
          <Col span={24}>
            <Button
              size="small"
              type="link"
              onClick={() => {
                window.location.href = `https://aoe.ms/replay/?gameId=${match.match_id}&profileId=${player.profile_id}`;
              }}
              icon={<DownloadOutlined />}
            >
              {t("Download match")}
            </Button>
          </Col>
        </Row>
      }
      title={
        <>
          {!!faction && faction.image?.url && (
            <Image src={faction.image?.url} width={20} height={20} />
          )}
          &nbsp;
          <Typography.Text
            className={
              profileIds.includes(player.profile_id?.toString())
                ? "font-bold"
                : ""
            }
            type={
              profileIds.includes(player.profile_id?.toString())
                ? "success"
                : ""
            }
          >
            {player.name?.slice(0, 20)}
          </Typography.Text>
          {!!player.rating && <>&nbsp; ({player.rating})</>}
        </>
      }
    >
      {children}
    </Popover>
  );
};

PopoverMember.propTypes = {
  children: PropTypes.object,
  t: PropTypes.func,
  player: PropTypes.object,
  match: PropTypes.object,
  faction: PropTypes.object,
  profileIds: PropTypes.array,
  teamIds: PropTypes.array,
};

PopoverMember.defaultProps = {
  profileIds: [],
  teamIds: [],
};

export default withTranslation()(PopoverMember);
