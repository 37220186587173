import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import reactStringReplace from "react-string-replace";

const Mentionify = ({ children, mentions, onClick }) =>
  reactStringReplace(children, /@(\w+)/g, (match, i) => (
    <Button
      key={match + i}
      type="link"
      className="m-0 p-0"
      onClick={() =>
        onClick(mentions.filter((mention) => mention.nickname === match)?.[0])
      }
    >
      @{match}
    </Button>
  ));

Mentionify.propTypes = {
  children: PropTypes.any,
  mentions: PropTypes.array,
  onClick: PropTypes.func,
};

Mentionify.defaultProps = {
  children: <></>,
  mentions: [],
  onClick: () => {},
};

export default Mentionify;
