import styled from "styled-components";

export const LinkPreviewStyle = styled.div`
  .react_tinylink_card {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }

  .react_tinylink_card_media {
    background-color: rgba(225, 232, 237, 0.6) !important;
  }
`;
