import React, { useContext, useState, forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Row,
  Col,
  Typography,
  Upload,
  Button,
  Modal,
  Mentions,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
  PaperClipOutlined,
  SendOutlined,
  GifOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import AuthContext from "context/AuthContext";
import { Formik } from "formik";
import { Input } from "formik-antd";
import isEmpty from "lodash/isEmpty";
import { withTranslation } from "react-i18next";
import GiphySearch from "../GiphySearch";
import { Gif } from "@giphy/react-components";
import axiosClient from "utils/axios";
import UserAvatar from "components/common/UserAvatar";
import { antMentionsDark } from "./ActivityFeedInput.module.scss";

const insertActivitiesQuery = loader(
  "queries/activities/insertActivity.graphql"
);
const updateActivitiesQuery = loader(
  "queries/activities/updateActivity.graphql"
);

const ActivityFeedInput = forwardRef(
  (
    {
      t,
      allowTitle = true,
      noBorder = false,
      activityFeedItem,
      onUpdate,
      parentActivityId,
      queries,
    },
    ref
  ) => {
    const [insertActivity, insertState] = useMutation(insertActivitiesQuery, {
      refetchQueries: Object.values(queries),
    });
    const [updateActivity, updateState] = useMutation(updateActivitiesQuery, {
      refetchQueries: Object.values(queries),
    });
    const [showGifModal, setShowGifModal] = useState(false);
    const { currentClan, token, user, currentMembers } = useContext(
      AuthContext
    );
    const innerRef = useRef();

    const validate = (values) => {
      const errors = {};
      let required = ["description"];
      required.forEach((field) => {
        if (!values[field] || isEmpty(values[field].toString().trim()))
          errors[field] = "Requerido";
      });

      return errors;
    };

    const onSubmit = async (
      values,
      { setSubmitting, validateForm, setFieldValue }
    ) => {
      try {
        validateForm();
        setSubmitting(true);

        const mentions = Mentions.getMentions(values.description).map(
          (t) => t.value
        );
        let mentionedUsers = currentMembers.filter((user) =>
          mentions.includes(user.nickname)
        );
        mentionedUsers = mentionedUsers.map((u) => ({
          id: u.id,
          nickname: u.nickname,
          clan_id: u.clan_id,
        }));

        if (!values.id) {
          const formData = new FormData();
          let files = values.files,
            gifs = null;
          if (!!files) {
            files.forEach((file) => formData.append("files", file));
            setFieldValue("filesLoading", true);
            const { data } = await axiosClient.post("/files/upload", formData, {
              headers: {
                "X-Path": "/uploads/",
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            });

            files = data;

            for (let file of Object.keys(files)) {
              files[file]["clan_id"] = currentClan.id;
              files[file]["object_type"] = "Activity";
            }
          }

          if (!!values.gifs) {
            gifs = [];
            for (let gif of values.gifs) {
              gifs.push({
                gif,
                clan_id: currentClan.id,
                object_type: "Activity",
              });
            }
          }

          await insertActivity({
            variables: {
              object: Object.assign(
                {
                  description: values.description,
                  clan_id: currentClan.id,
                },
                !!gifs ? { gifs: { data: gifs } } : {},
                !!files ? { files: { data: files } } : {},
                !!parentActivityId ? { activity_id: parentActivityId } : {},
                !!mentions ? { mentions: mentionedUsers } : {}
              ),
            },
          });
        } else {
          await updateActivity({
            variables: {
              id: values.id,
              object: {
                description: values.description,
              },
            },
          });
          onUpdate();
        }
        setFieldValue("description", null);
        setFieldValue("files", null);
        setFieldValue("gifs", null);
      } catch (err) {
        // TODO: Delete files
        console.error(err);
      } finally {
        setFieldValue("filesLoading", false);
        setSubmitting(false);
      }
    };

    return (
      <Formik
        initialValues={{
          id: activityFeedItem?.id,
          title: activityFeedItem?.title,
          description: activityFeedItem?.description,
          files: activityFeedItem?.files,
          gifs: activityFeedItem?.gifs,
          mentions: activityFeedItem?.mentions,
          filesLoading: false,
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ values, submitForm, setFieldValue }) => {
          return !values.id ? (
            <>
              <Card
                type="inner"
                size={"small"}
                style={noBorder ? { border: 0 } : {}}
                title={
                  !!allowTitle && (
                    <Typography.Title level={5}>
                      {t("Activity Feed Input Share")}
                    </Typography.Title>
                  )
                }
                className={"ant-card-with-opacity"}
              >
                <Row gutter={[10, 10]}>
                  <Col>
                    <UserAvatar user={user} loading={insertState.loading} />
                  </Col>
                  <Col flex="auto">
                    <Mentions
                      inputMode="text"
                      className={antMentionsDark}
                      disabled={insertState.loading}
                      rows={1}
                      autoSize
                      value={values.description}
                      size="large"
                      placeholder={t("Activity Feed Input Description")}
                      onKeyPressCapture={(e) => {
                        if (e.key === "Enter") {
                          if (!e.shiftKey) {
                            e.preventDefault();
                            submitForm();
                          }
                        }
                      }}
                      ref={ref || innerRef}
                      onChange={(value) => setFieldValue("description", value)}
                    >
                      {currentMembers.map((user) => (
                        <Mentions.Option key={user.id} value={user.nickname}>
                          {user.nickname}
                        </Mentions.Option>
                      ))}
                    </Mentions>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col flex="auto" className="text-left">
                    <Button
                      icon={<GifOutlined />}
                      disabled={(values.gifs || []).length >= 5}
                      className="mr-1"
                      onClick={() =>
                        setShowGifModal((showGifModal) => !showGifModal)
                      }
                    />
                    <Upload
                      listType="picture"
                      disabled={(values.files || []).length >= 5}
                      fileList={values.files || []}
                      onRemove={(file) => {
                        const files = !!values.files ? [...values.files] : [];
                        const index = files.indexOf(file);
                        files.splice(index, 1);
                        setFieldValue("files", files);
                      }}
                      beforeUpload={(file) => {
                        let newFile = file;
                        if (file.type.includes("image/")) {
                          let reader = new FileReader();
                          reader.readAsDataURL(file);
                          reader.onloadend = () => {
                            newFile.url = reader.result;
                            const files = !!values.files
                              ? [...values.files, newFile]
                              : [newFile];
                            setFieldValue("files", files);
                          };
                        } else {
                          const files = !!values.files
                            ? [...values.files, newFile]
                            : [newFile];
                          setFieldValue("files", files);
                        }
                        return false;
                      }}
                      className="upload-list-inline w-full"
                    >
                      <Button
                        disabled={(values.files || []).length >= 5}
                        icon={<PaperClipOutlined />}
                      />
                    </Upload>
                  </Col>
                  <Col>
                    <Button
                      loading={insertState.loading || values.filesLoading}
                      type="primary"
                      icon={<SendOutlined />}
                      onClick={submitForm}
                    >
                      {t("Activity Feed Input Send")}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  {!!values?.gifs &&
                    values?.gifs.length > 0 &&
                    values.gifs.map((gif, i) => (
                      <Col className="my-2 mr-2" key={i}>
                        <div className="ant-upload-list-picture">
                          <Row
                            align="middle"
                            className="ant-upload-list-item"
                            style={{ height: "inherit" }}
                          >
                            <Col>
                              <Gif
                                gif={gif}
                                height={48}
                                noLink
                                hideAttribution
                              />
                            </Col>
                            <Col>
                              <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  const gifs = [...values.gifs];
                                  const index = gifs.indexOf(gif);
                                  gifs.splice(index, 1);
                                  setFieldValue("gifs", gifs);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    ))}
                </Row>
              </Card>
              <Modal
                width={545}
                footer={false}
                visible={showGifModal}
                onCancel={() => setShowGifModal(false)}
              >
                <div className="pt-6">
                  <GiphySearch
                    onGifClick={(gif) => {
                      const gifs = !!values.gifs
                        ? [...values.gifs, gif]
                        : [gif];
                      setFieldValue("gifs", gifs);
                      setShowGifModal(false);
                    }}
                  />
                </div>
              </Modal>
            </>
          ) : (
            <Input
              disabled={updateState.loading}
              name="description"
              size="large"
              placeholder={t("Activity Feed Input Description")}
              onPressEnter={submitForm}
            />
          );
        }}
      </Formik>
    );
  }
);

ActivityFeedInput.propTypes = {
  t: PropTypes.func,
  allowtitle: PropTypes.bool,
  noBorder: PropTypes.bool,
  activityFeedItem: PropTypes.object,
  onUpdate: PropTypes.func,
  parentActivityId: PropTypes.number,
  queries: PropTypes.object,
};

ActivityFeedInput.defaultProps = {
  allowTitle: true,
  noBorder: false,
};

export default withTranslation("translation", { withRef: true })(
  ActivityFeedInput
);
