import React, { useContext, useRef, useEffect, useCallback } from "react";
import { Card, Row, Col, Image, Button, Typography, Avatar } from "antd";
import { AreaChartOutlined, DownloadOutlined } from "@ant-design/icons";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import PropTypes from "prop-types";
import groupBy from "lodash/groupBy";
import { withTranslation } from "react-i18next";
import Moment from "react-moment";
import AuthContext from "../../../../context/AuthContext";
import { MiniActionCard } from "./Stories.styles";
import PopoverMember from "../PopoverMember";
// import debounce from 'lodash.debounce';

const Stories = ({ t, matches, factions, users }) => {
  const { currentGame } = useContext(AuthContext);
  const carousel = useRef();
  const lastScrollValue = useRef();

  // const debouncedMoveSlide = useRef(debounce(e => {
  //   if(!!carousel.current) {
  //     if(e.deltaY < 0) {
  //       carousel.current.slidePrev();
  //     } else {
  //       carousel.current.slideNext();
  //     }
  //   }
  // }, 50))

  const profileIds = users.map((user) => user.provider_id_alt);

  const onScroll = useCallback(
    (e) => {
      e.preventDefault();
      if (e.deltaY < 0) carousel.current.slidePrev();
      else carousel.current.slideNext();
    },
    [carousel]
  );

  useEffect(() => {
    if (!!carousel.current && !!carousel.current.RootElement) {
      carousel.current.RootElement.addEventListener("mousewheel", onScroll, {
        passive: false,
      });

      lastScrollValue.current = carousel.current.RootElement.scrollY;

      return () => {
        if (!!carousel.current && !!carousel.current.RootElement) {
          carousel.current.RootElement.removeEventListener(
            "mousewheel",
            onScroll
          );
        }
      };
    }
  }, [onScroll, carousel, lastScrollValue]);

  return (
    <Row
      className="w-full"
      onScroll={(e, b) => {
        e.preventDefault();
      }}
    >
      <Col span={24}>
        <AliceCarousel
          ref={(el) => (carousel.current = el)}
          mouseTracking
          infinite
          autoWidth
          disableDotsControls
          disableButtonsControls
          controlsStrategy="default"
          autoPlay
          autoPlayInterval={3000}
          items={matches.map((match) => {
            const teams = groupBy(match.players, (player) =>
              Math.abs(player.team)
            );
            const teamIds = Object.keys(teams);

            let factionsHash = {};
            factions.forEach((faction) => {
              if (!!faction.code)
                factionsHash[parseInt(faction.code)] = faction;
            });

            return (
              <div
                className="item"
                style={{
                  width: teamIds.length * 215,
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginTop: 10,
                }}
              >
                <MiniActionCard className="h-full">
                  <Card
                    className="h-full"
                    size="small"
                    type="inner"
                    style={{ fontSize: 11 }}
                    title={
                      <>
                        <Row
                          justify="space-between"
                          align="middle"
                          className="w-full text-xs"
                        >
                          <Col>
                            {match.ranked && (
                              <>
                                <AreaChartOutlined />
                                &nbsp;
                              </>
                            )}
                            {!!match.game_type && (
                              <>
                                &nbsp;
                                {t(
                                  `${currentGame.code}:game_type.${match.game_type}`
                                )}
                                :&nbsp;
                              </>
                            )}
                            {!!match.map_type &&
                              t(
                                `${currentGame.code}:map_type.${match.map_type}`
                              )}
                            {match.ranked && <>&nbsp; ({t("Ranked")})</>}
                          </Col>
                          <Col>
                            {!!match.finished && (
                              <>
                                <Moment fromNow>{match.finished}</Moment>
                                &nbsp; &nbsp;
                              </>
                            )}
                            <Button
                              shape="circle"
                              type="primary"
                              size="small"
                              onClick={() => {
                                window.location.href = `https://aoe.ms/replay/?gameId=${match.match_id}&profileId=${match.players?.[0]?.profile_id}`;
                              }}
                              icon={<DownloadOutlined />}
                            />
                          </Col>
                        </Row>
                      </>
                    }
                  >
                    <Row
                      gutter={[10, 0]}
                      style={{ position: "relative", cursor: "grab" }}
                    >
                      {teamIds.length === 2 && (
                        <Avatar
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginLeft: -13,
                            marginTop: -13,
                            color: "white",
                            backgroundColor: "transparent",
                            border: "1px solid rgba(255,255,255,0.2)",
                            cursor: "grab",
                          }}
                          size={26}
                        >
                          VS
                        </Avatar>
                      )}
                      {teamIds.map((team, i) => (
                        <Col span={24 / teamIds.length} key={i}>
                          <Row>
                            <Col span={24}>
                              {teams[team].slice(0, 4).map((player, j) => {
                                const faction = factionsHash[player.civ];
                                return i % 2 === 1 ? (
                                  <Row key={j} justify="end" className="w-full">
                                    <Col style={{ cursor: "pointer" }}>
                                      <PopoverMember
                                        player={player}
                                        match={match}
                                        faction={faction}
                                        profileIds={profileIds}
                                        teamIds={teamIds}
                                      >
                                        <>
                                          {!!player.rating && (
                                            <>
                                              {player.rating_change > 0 ? (
                                                <Typography.Text type="success">
                                                  +{player.rating_change}
                                                </Typography.Text>
                                              ) : (
                                                <Typography.Text type="danger">
                                                  {player.rating_change}
                                                </Typography.Text>
                                              )}
                                              &nbsp; ({player.rating}) &nbsp;
                                            </>
                                          )}
                                          <Typography.Text
                                            className={
                                              profileIds.includes(
                                                player.profile_id?.toString()
                                              )
                                                ? "font-bold"
                                                : ""
                                            }
                                            type={
                                              profileIds.includes(
                                                player.profile_id?.toString()
                                              )
                                                ? "success"
                                                : ""
                                            }
                                          >
                                            {player.name?.slice(0, 20)}
                                          </Typography.Text>
                                          {!!faction && faction.image?.url && (
                                            <Image
                                              src={faction.image?.url}
                                              width={20}
                                              height={20}
                                            />
                                          )}
                                          {!!player.won && (
                                            <span
                                              className={`ra ra-trophy text-xs relative ml-1`}
                                            />
                                          )}
                                        </>
                                      </PopoverMember>
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row key={j}>
                                    <Col style={{ cursor: "pointer" }}>
                                      <PopoverMember
                                        player={player}
                                        match={match}
                                        faction={faction}
                                        profileIds={profileIds}
                                        teamIds={teamIds}
                                      >
                                        <>
                                          {!!player.won && (
                                            <span
                                              className={`ra ra-trophy text-xs relative mr-1`}
                                            />
                                          )}
                                          {!!faction && faction.image?.url && (
                                            <Image
                                              src={faction.image?.url}
                                              width={20}
                                              height={20}
                                            />
                                          )}
                                          <Typography.Text
                                            className={
                                              profileIds.includes(
                                                player.profile_id?.toString()
                                              )
                                                ? "font-bold"
                                                : ""
                                            }
                                            type={
                                              profileIds.includes(
                                                player.profile_id?.toString()
                                              )
                                                ? "success"
                                                : ""
                                            }
                                          >
                                            {player.name?.slice(0, 20)}
                                          </Typography.Text>
                                          {!!player.rating && (
                                            <>
                                              &nbsp; ({player.rating}) &nbsp;
                                              {player.rating_change > 0 ? (
                                                <Typography.Text type="success">
                                                  +{player.rating_change}
                                                </Typography.Text>
                                              ) : (
                                                <Typography.Text type="danger">
                                                  {player.rating_change}
                                                </Typography.Text>
                                              )}
                                            </>
                                          )}
                                        </>
                                      </PopoverMember>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                </MiniActionCard>
              </div>
            );
          })}
        />
      </Col>
    </Row>
  );
};

Stories.propTypes = {
  matches: PropTypes.array,
  factions: PropTypes.array,
};

Stories.defaultProps = {
  matches: [],
  factions: [],
};

export default withTranslation(["translations", "aoe2de"])(Stories);
